<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-shrink">
              <span class="uk-text-bold">Nama Tahapan</span>
            </th>
            <th class="uk-table-shrink">
              <span class="uk-text-bold">Urutan</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Jenis Kandang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Catatan</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Terakhir Diubah</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold uk-display-block uk-text-center">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="activities.meta.total_count>0">
            <tr
              v-for="(act, i) in activities.data"
              :key="i"
            >
              <td>{{ act.name ? act.name : '-' }}</td>
              <td>{{ act.sequence ? act.sequence : '-' }}</td>
              <td>{{ act.sequence ? act.cage_category : '-' }}</td>
              <td>{{ act.note ? act.note : '-' }}</td>
              <td>{{ act.updated_at ? formatDate(act.updated_at) : '-' }}</td>
              <td>{{ act.updated_by ? act.updated_by : '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Tahapan`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showDeleteConfirmModal(act)"
                > 
                <img
                  v-if="isCanAccessUser(`edit`, `Tahapan`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  alt=""
                  class="img-click"
                  @click="goToEdit(act.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Tahapan`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-left img-click"
                  @click="showDetail(act.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="7"
          />
        </template>
        <template v-else>
          <loading-table :colspan="7" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="activities.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
  </div>
</template>

<script>
import Pagination from "@/components/globals/Pagination"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      activities: 'activity/activities',
      meta: 'activity/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getActivity(this.meta)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getActivity(this.meta)
    this.tableProps ={
      ...this.meta,
      setMeta: this.setMeta,
      total_count: this.activities.meta.total_count
    }
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getActivity: 'activity/getActivity'
    }),
    ...mapMutations({
      setMeta: 'activity/SET_META',
      setModalDelete: 'activity/SET_MODAL_DELETE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    showDetail (id) {
      this.$router.push(`/admin/tahapan/detail/${id}`)
    },
    goToEdit (id) {
      this.$router.push(`/admin/tahapan/edit/${id}`)
    },
    showDeleteConfirmModal (employee) {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(employee)
    }
  }
}
</script>
